import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Showroom from "../components/showroom"
import ShowWork from "../components/showWork/windowEmpty/showWorkWindow.js"
import { useStaticQuery, graphql } from "gatsby"

// import data from "../works.json"

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulWork {
        edges {
          node {
            id
            category
            enTech
            enTitle
            year
            esTech
            esTitle
            frTech
            frTitle
            enDescription {
              json
            }
            esDescription {
              json
            }
            frDescription {
              json
            }
            images {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_noBase64
              }
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="Home" />
      <Showroom
        path={props.location.pathname}
        video={Math.random() >= 0.5}
      ></Showroom>
      <ShowWork data={data} dataUrl={props.location.search}></ShowWork>
    </div>
  )
}
export default IndexPage
